import React, { useEffect, useState } from "react";
// import NavBar from "../../../components/JobSeekerComponents/NavBar/NavBar";
import { Button, Typography } from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
// import { CommitSharp, Token } from "@mui/icons-material";
import { fetchEmployerPaymentById } from "../../api/employer/payments";
import Vacanzi from "../../assets/vacanzi.png";

const EmployerInvoice = () => {
  const [invoiceData, setInvoiceData] = useState();
  const token = localStorage.getItem("token");
  const { invoiceId } = useParams();

  useEffect(() => {
    const getInvoiceData = async () => {
      try {
        const response = await fetchEmployerPaymentById(invoiceId, token);
        setInvoiceData(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    getInvoiceData();
  }, [token, invoiceId]);
  
  function numberToWords(num) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num === 0) return "Zero"; // Handle zero case

    function convertChunk(chunk) {
      let words = "";
      if (chunk >= 100) {
        words += units[Math.floor(chunk / 100)] + " Hundred ";
        chunk %= 100;
      }
      if (chunk >= 20) {
        words += tens[Math.floor(chunk / 10)] + " ";
        chunk %= 10;
      } else if (chunk >= 10) {
        words += teens[chunk - 10] + " ";
        chunk = 0;
      }
      if (chunk > 0 && chunk < 10) {
        words += units[chunk] + " ";
      }
      return words.trim();
    }

    let result = "";
    if (num >= 1e6) {
      result += convertChunk(Math.floor(num / 1e6)) + " Million ";
      num %= 1e6;
    }
    if (num >= 1e3) {
      result += convertChunk(Math.floor(num / 1e3)) + " Thousand ";
      num %= 1e3;
    }
    if (num > 0) {
      result += convertChunk(num);
    }

    return result.trim();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric", // Full year (e.g., "2024")
      month: "numeric", // Full month name (e.g., "December")
      day: "numeric", // Numeric day (e.g., "20")
    };
    return date.toLocaleDateString("en-US", options);
  };

  const downloadInvoice = async () => {
    const invoiceElement = document.getElementById("invoice");
  
    // Render HTML content to canvas
    const canvas = await html2canvas(invoiceElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
  
    // Create jsPDF instance
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio
  
    // Calculate how many pages we need
    let yPosition = 0;
    const pageHeight = 297; // A4 page height in mm
  
    // If the content exceeds the page height, split it into pages
    while (yPosition < pdfHeight) {
      // Add the current portion of the image to the PDF
      pdf.addImage(imgData, "PNG", 0, -yPosition, pdfWidth, pdfHeight);
  
      // Move the yPosition for the next page
      yPosition += pageHeight;
  
      // Add a new page if there's more content
      if (yPosition < pdfHeight) {
        pdf.addPage();
      }
    }
  
    // Save the PDF
    pdf.save("Invoice.pdf");
  };

  return (
    <div
      
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <NavBar /> */}

      <Button
        onClick={downloadInvoice}
        style={{
          backgroundColor: "#423BFA",
          borderRadius: "20px",
          textTransform: "none",
          marginTop: "20px",
        }}
        variant="contained"
      >
        Download Invoice
      </Button>

      {/* Invoice content */}
      <div
      id="invoice"
      
        style={{ width: "800px", padding: "20px", backgroundColor: "white" }}
      >
        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "50.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center	",
                  }}
                >
                  <img
                    src={Vacanzi}
                    alt="Logo"
                    style={{ width: 110, height: 110 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ fontSize: "30px" }}>
                      Vacanzi Hyperthink Ltd
                    </Typography>
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                      Tax Invoice
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      ORIGINAL FOR RECIPIENT
                    </Typography>
                  </Box>
                  <img
                    src="/qrdummy.png"
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                      marginRight: "20px",
                      visibility: "hidden",
                    }}
                  />
                </Box>
              </td>
            </tr>

            <tr style={{ height: "12.2pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Organization Details
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "147.75pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Invoice
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "2pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Billing /Supplier Information
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "133.35pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: "12px", color: "#20CA09FF" }}>
                    Bill-to/Sell-to Customer
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Legal Name:
                    {invoiceData?.organizationId?.organizationName}
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Trade Name:
                    {invoiceData?.organizationId?.organizationName}
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Address: 10/1,VK Plaza,BANGALORE-560043,India
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Receipient place : BANGALORE
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Place of Supply: 29
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Receipient State code - 29
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    P.A.N. No. - AAICM5579P
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    GSTIN:29AAICM5579P1ZD
                  </Typography>
                </Box>
              </td>
              <td
                style={{
                  width: "147.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  {/* <Typography sx={{ fontSize: "11px" }}>
                    Version 1.03
                  </Typography> */}
                  <Typography sx={{ fontSize: "11px" }}>
                    Document No : {invoiceData?.invoiceId}
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Supply Type : B2B
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Document Type : INV
                  </Typography>
                </Box>
              </td>
              <td
                colSpan={4}
                style={{
                  width: "218.52pt",
                  borderRight: "0.75pt solid",
                  borderLeft: "1pt solid",
                  borderBottom: "0.75pt solid",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: "11px" }}>
                    VK Plaza, Ramamurthynagar Main Road, Dodda Bansawadi,
                    Bengaluru - 560043
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>India</Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Supplier Place : Bengaluru
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Supplier State Code : 29
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    PAN No: ABJCS3745D
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    GSTN No : 29ABJCS3745D1Z5
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    CIN No : U72900KA2022PTC166004
                  </Typography>
                </Box>
              </td>
            </tr>

            <tr />

            <tr style={{ height: "19pt" }}>
              <td
                style={{
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  S.No
                </Typography>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Service Description
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Rate
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "11.55pt" }}>
              <td
                style={{
                  width: "21.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px" }}>1</Typography>
              </td>
              <td
                colSpan="5"
                style={{
                  width: "466.4pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px" }}>
                  Subscription upgraded to {invoiceData?.tier}
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.amount}
                </Typography>
              </td>
            </tr>
            

            <tr style={{ height: "10.5pt" }}>
              <td
                colSpan="4"
                rowSpan="8"
                style={{
                  width: "397.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  verticalAlign: "top",
                  marginTop: "50px",
                }}
              ></td>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  Item totalAmount
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.amount}
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  Total Taxable Value
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.costDetails?.amountWithoutGst}
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "9pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  GST Rate
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  18%
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  CGST
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.costDetails?.cgstAmount}
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "10pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  SGST
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.costDetails?.sgstAmount}
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "17.4pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  Gross Amount/Total Invoice Value
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.amount}
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "10.25pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "90.25pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  Payablein
                </Typography>
              </td>
              <td
                style={{
                  width: "65.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                ₹{invoiceData?.amount} INR
                </Typography>
              </td>
            </tr>
          </table>
          <Box>
            <Typography
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1,marginTop:'-100px' }}
            >
              * Total In Words ****{" "}
              {numberToWords(invoiceData?.amount)} Rupees Only Whether
              Tax is payable <br/>on reverse charge : No.
            </Typography>
          </Box>
          <Box marginTop={2} marginBottom={2}>
            <Typography
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * This invoice is recognised subject to realization of Payment.
            </Typography>
            <Typography
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * Refer Terms and Conditions at{" "}
              <a href="www.vacanzi.com" style={{ color: "blue" }}>
                vacanziUrl
              </a>
            </Typography>
            <Typography
              sx={{ fontSize: "11px", textAlign: "start", marginLeft: 1 }}
            >
              * All disputes subject to Bangalore Jurisdiction only.
            </Typography>
          </Box>
        </div>

        {/* payment details */}

        <div style={{marginBottom:'40px'}}>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="6"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "20.3pt" }}>
              <td
                colSpan="6"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "bottom",
                    }}
                  >
                    Payment Details
                  </Typography>
                </Box>
              </td>
            </tr>

            <tr style={{ height: "19pt" }}>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  S.No
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Transaction Id
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Payment Mode
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Payment Date
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Amount
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "11.55pt" }}>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  1
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.transactionDetails?.transactionId}
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  Wallet
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {formatDate(invoiceData?.transactionDetails?.date)}
                </Typography>
              </td>
              <td
                style={{
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography sx={{ fontSize: "11px", textAlign: "center" }}>
                  {invoiceData?.amount}
                </Typography>
              </td>
            </tr>
          </table>
        </div>

        {/* anexure part */}

        <div>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{ marginLeft: "5.85pt", borderCollapse: "col	lapse" }}
          >
            <tr>
              <td
                colSpan="7"
                style={{
                  width: "800.65pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "0.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              ></td>
            </tr>
            <tr style={{ height: "50.3pt" }}>
              <td
                colSpan="7"
                style={{
                  width: "554.65pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center	",
                  }}
                >
                  <img
                    src={Vacanzi}
                    alt=""
                    style={{ width: 110, height: 110 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ fontSize: "30px" }}>
                      Vacanzi Hyperthink Ltd
                    </Typography>
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                      Anexure
                    </Typography>
                  </Box>
                  <img
                    src="/qrdummy.png"
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                      marginRight: "20px",
                      visibility: "hidden",
                    }}
                  />
                </Box>
              </td>
            </tr>

            <tr style={{ height: "12.2pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Customer Details
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "147.75pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Invoice
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "2pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "0.75pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Billing /Supplier Information
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "133.35pt" }}>
              <td
                colSpan="2"
                style={{
                  width: "186.38pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "0.75pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: "12px", color: "#20CA09FF" }}>
                    Bill-to/Sell-to Customer
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Legal Name:
                    {invoiceData?.organizationId?.organizationName}
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Trade Name:
                    {invoiceData?.organizationId?.organizationName}
                  </Typography>

                  <Typography sx={{ fontSize: "11px" }}>
                    Address: VK Plaza,BANGALORE-560043,India
                  </Typography>
                </Box>
              </td>
              <td
                style={{
                  width: "147.75pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: "11px" }}>
                    Document No : {invoiceData?.invoiceId}
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Document Type : INV
                  </Typography>
                </Box>
              </td>
              <td
                colSpan={4}
                style={{
                  width: "218.52pt",
                  borderRight: "0.75pt solid",
                  borderLeft: "1pt solid",
                  borderBottom: "0.75pt solid",
                  verticalAlign: "top",
                }}
              >
                <Box sx={{ marginLeft: 2 }}>
                  <Typography sx={{ fontSize: "11px" }}>
                    VK Plaza,Ramamurthynagar Main Road, Dodda Bansawadi,
                    Bengaluru - 560043
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>India</Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Supplier Place : Bengaluru
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    Supplier State Code : 29
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    PAN No: ABJCS3745D
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    GSTN No : 29ABJCS3745D1Z5
                  </Typography>
                  <Typography sx={{ fontSize: "11px" }}>
                    CIN No : U72900KA2022PTC166004
                  </Typography>
                </Box>
              </td>
            </tr>

            <tr />
            <tr style={{ height: "12.85pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  Sl.No
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Description of Services
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Start Date
                </Typography>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "0.75pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                  backgroundColor: "#aeeded",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  End Date
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  1
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.subscriptionId?.cvDownloads} CV Downloads
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.transactionDetails?.date)}
                </Typography>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.validUntil)}
                </Typography>
              </td>
            </tr>

            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  2
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.subscriptionId?.invites} Invites
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.transactionDetails?.date)}
                </Typography>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.validUntil)}
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  3
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.subscriptionId?.jobPosts} Job Posts
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.transactionDetails?.date)}
                </Typography>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.validUntil)}
                </Typography>
              </td>
            </tr>
            <tr style={{ height: "13.35pt" }}>
              <td
                style={{
                  width: "28.12pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 1pt 1pt 0.75pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  1
                </Typography>
              </td>
              <td
                colSpan="3"
                style={{
                  width: "371.65pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {invoiceData?.subscriptionId?.userAccess} User Access
                </Typography>
              </td>
              <td
                colSpan="2"
                style={{
                  width: "77.3pt",
                  borderStyle: "solid",
                  borderWidth: "1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.transactionDetails?.date)}
                </Typography>
              </td>
              <td
                style={{
                  width: "74.53pt",
                  borderStyle: "solid",
                  borderWidth: "1pt 0.75pt 1pt 1pt",
                  verticalAlign: "top",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  {formatDate(invoiceData?.validUntil)}
                </Typography>
              </td>
            </tr>
          </table>
        </div>
      </div>

    
    </div>
  );
};

export default EmployerInvoice;