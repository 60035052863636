import React, { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Button, Typography } from "@mui/material";
import AdminDrawerList from "../../../components/Drawer/AdminDrawerList";
import baseUrl from "../../../config";

const EmployerSubscriptionForm = ({ editData, onSuccess }) => {
  const isEditing = Boolean(editData); // Check if form is in edit mode
  const getToken = () => localStorage.getItem("token");

  const token = getToken();
  const [formData, setFormData] = useState({
    userAccess: "",
    tier: "general",
    subscriptionFee: "",
    validityInDays: 30,
    subType: "database", // Default to database
    jobPosts: "",
    invites: "",
    client: "", // Will store organizationId
    recruiterIds: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [organizations, setOrganizations] = useState([]); // Stores organization list

  const tiers = ["general", "premium", "custom"];
  const subTypes = ["database", "jobposting"];

  useEffect(() => {
    if (editData) {
      setFormData(editData); // Populate form with existing data if editing
    }
  }, [editData]);

  // Fetch Organizations from API with Authorization Token
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await fetch(`${baseUrl}/organization/getOrgs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include token in headers
          },
        });

        const data = await response.json();
        if (response.ok) {
          setOrganizations(data); // Store organization list
        } else {
          console.error("Failed to fetch organizations:", data.message);
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizations();
  }, [token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOrganizationChange = (e) => {
    const selectedOrg = organizations.find((org) => org._id === e.target.value);
    setFormData({ ...formData, client: selectedOrg._id }); // Store organizationId
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const url = isEditing
      ? `${baseUrl}/sub/edit/${editData._id}`
      : `${baseUrl}/sub/create`;
    const method = isEditing ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(
          isEditing
            ? "Subscription updated successfully!"
            : "Subscription created successfully!"
        );
        onSuccess(); // Callback to refresh subscriptions list
      } else {
        setMessage(data.message || "Failed to process request.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <AdminDrawerList>
      <Box
        sx={{
          maxWidth: 450,
          mx: "auto",
          mt: 4,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: "white",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
          {isEditing
            ? "Edit Employer Subscription"
            : "Create Employer Subscription"}
        </Typography>

        <form onSubmit={handleSubmit}>
          {/* Subscription Type */}
          <TextField
            fullWidth
            select
            label="Subscription Type"
            name="subType"
            value={formData.subType}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            {subTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          {/* Tier Selection */}
          <TextField
            fullWidth
            select
            label="Subscription Tier"
            name="tier"
            value={formData.tier}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            {tiers.map((tier) => (
              <MenuItem key={tier} value={tier}>
                {tier.charAt(0).toUpperCase() + tier.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          {/* Subscription Fee */}
          <TextField
            fullWidth
            label="Subscription Fee (₹)"
            type="number"
            name="subscriptionFee"
            value={formData.subscriptionFee}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />

          {/* Validity */}
          <TextField
            fullWidth
            label="Validity (Days)"
            type="number"
            name="validityInDays"
            value={formData.validityInDays}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />

          {/* User Access */}
          <TextField
            fullWidth
            label="User Access"
            type="number"
            name="userAccess"
            value={formData.userAccess}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />

          {/* Organization Dropdown */}
          {formData.subType === "jobposting" && (
            <TextField
              fullWidth
              select
              label="Client (Organization)"
              name="client"
              value={formData.client}
              onChange={handleOrganizationChange}
              sx={{ mb: 2 }}
            >
              {organizations.map((org) => (
                <MenuItem key={org._id} value={org._id}>
                  {org.organizationName}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Additional Fields for "jobposting" */}
          {formData.subType === "jobposting" && (
            <>
              <TextField
                fullWidth
                label="Job Posts"
                type="number"
                name="jobPosts"
                value={formData.jobPosts}
                onChange={handleChange}
                sx={{ mb: 2 }}
                required
              />

              <TextField
                fullWidth
                label="Invites"
                type="number"
                name="invites"
                value={formData.invites}
                onChange={handleChange}
                sx={{ mb: 2 }}
                required
              />

              <TextField
                fullWidth
                label="Recruiter IDs"
                type="number"
                name="recruiterIds"
                value={formData.recruiterIds}
                onChange={handleChange}
                sx={{ mb: 2 }}
                required
              />
            </>
          )}

          {/* Submit Button */}
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#f2426d",
              "&:hover": { backgroundColor: "#d81b60" },
            }}
            disabled={loading}
          >
            {loading
              ? "Processing..."
              : isEditing
              ? "Update Subscription"
              : "Create Subscription"}
          </Button>

          {/* Response Message */}
          {message && (
            <Typography sx={{ mt: 2, textAlign: "center", color: "green" }}>
              {message}
            </Typography>
          )}
        </form>
      </Box>
    </AdminDrawerList>
  );
};

export default EmployerSubscriptionForm;
