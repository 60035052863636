import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Button, Box, Tabs, Tab, Grid } from "@mui/material";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import EmployerSubscription from "./EmployerSubscription/EmployerSubscription";
import JobSeekerSubscription from "./JobSeekerSubscription/JobSeekerSubscription";

const Subscriptions = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("employerSubscriptions");
  const location = useLocation();
  const { jobId, organizationId } = location.state || {};

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCreateSubscription = () => {
    if (activeTab === "employerSubscriptions") {
      navigate("/employer/subscriptionform"); // Navigate to Employer Subscription Form
    } else if (activeTab === "jobSeekersSubscription") {
      navigate("/jobseeker/subscriptionform"); // Navigate to Job Seeker Subscription Form
    }
  };

  const postJobButtonStyles = {
    color: "white",
    borderColor: "white",
    backgroundColor: "#f2426d",
    padding: "8px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#d81b60",
      borderColor: "white",
    },
  };

  return (
    <AdminDrawerList>
      <Box sx={{ mt: 2, mb: 5 }}>
        {/* Tabs and Button in a Grid for better layout */}
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => handleButtonClick(newValue)}
              textColor="secondary"
              indicatorColor="white"
              sx={{
                "& .MuiTab-root": {
                  fontWeight: 600,
                  textTransform: "none",
                  borderRadius: "8px",
                  bgcolor: "#f5f5f5",
                  px: 6,
                  "&.Mui-selected": {
                    bgcolor: "green",
                    color: "white",
                  },
                },
              }}
            >
              <Tab
                value="employerSubscriptions"
                label="Employer Subscriptions"
              />
              <Tab
                value="jobSeekersSubscription"
                label="Candidates Subscriptions"
              />
            </Tabs>
          </Grid>

          <Grid item>
            {/* Button is now inside the Grid, ensuring visibility */}
            <Button sx={postJobButtonStyles} onClick={handleCreateSubscription}>
              Create Subscription
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Dynamic Content */}
      <Box>
        {activeTab === "employerSubscriptions" && (
          <EmployerSubscription jobId={jobId} organizationId={organizationId} />
        )}
        {activeTab === "jobSeekersSubscription" && (
          <JobSeekerSubscription
            jobId={jobId}
            organizationId={organizationId}
          />
        )}
      </Box>
    </AdminDrawerList>
  );
};

export default Subscriptions;
