import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchEmployerPaymentData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/orgInvoices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.map((item, index) => ({
            siNo: index + 1,
            id:item?._id,
            transactionId: item.transactionDetails.transactionId,
            amount: item.transactionDetails.amount,
            type: item.transactionDetails.type,
            description: item.transactionDetails.description,
            date: item.transactionDetails.date,
            gstAmount: item.costDetails.gstAmount,
            cgstAmount: item.costDetails.cgstAmount,
            sgstAmount: item.costDetails.sgstAmount,
            amountWithoutGst: item.costDetails.amountWithoutGst,
            invoiceId: item.invoiceId,
            organizationId: item.organizationId._id,
            organizationName: item.organizationId.organizationName,
            subscriptionId: item.subscriptionId._id,
            tier: item.subscriptionId.tier,
            subscriptionFee: item.subscriptionId.subscriptionFee,
            subscribedOn: item.subscribedOn,
            validUntil: item.validUntil,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            isActive: item.subscriptionId.isActive,
            recruiterIds: item.subscriptionId.recruiterIds,
          }));
          

        return result;

    } catch (error) {
        console.error('Error fetching organization payment details:', error);
        throw error;
    }
};

export const fetchEmployerPaymentById = async (empId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetch(`${baseUrl}/orgInvoice/${empId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
    );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response.ok) {
            throw new Error("Failed to fetch Organization details");
        }

        // Parse and return JSON response
        return await response.json();
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};


export const fetchJobSeekerPaymentData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jsInvoices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.map((item, index) => ({
            siNo: index + 1,
            id:item?._id,
            transactionId: item.transactionDetails.transactionId,
            amount: item.transactionDetails.amount,
            type: item.transactionDetails.type,
            description: item.transactionDetails.description,
            date: item.transactionDetails.date,
            gstAmount: item.costDetails.gstAmount,
            cgstAmount: item.costDetails.cgstAmount,
            sgstAmount: item.costDetails.sgstAmount,
            amountWithoutGst: item.costDetails.amountWithoutGSt,
            invoiceId: item.invoiceId,
            userId: item.userId._id,
            userName: item.userId.fullName,
            userEmail: item.userId.email,
            userPhone: item.userId.phone,
            subscriptionId: item.subscriptionId._id,
            tier: item.subscriptionId.tier,
            subscriptionFee: item.subscriptionId.subscriptionFee,
            subscribedOn: item.subscribedOn,
            validUntil: item.validUntil,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            isActive: item.subscriptionId.isActive,
          }));
          

        return result;

    } catch (error) {
        console.error('Error fetching jobseeker payment details:', error);
        throw error;
    }
};

export const fetchJobSeekerPaymentById = async (empId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetch(`${baseUrl}/jsInvoice/${empId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
    );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response.ok) {
            throw new Error("Failed to fetch Organization details");
        }

        // Parse and return JSON response
        return await response.json();
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};