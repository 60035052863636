import baseUrl from "../../config";

export const getTopCitiesApi = async (query = "", isTopCity = false) => {
  try {
    // Construct query parameters
    const params = new URLSearchParams();
    if (query) params.append("query", query); // Add query parameter if provided
    if (isTopCity) params.append("topCity", true); // Add topCity parameter if true

    // Make the API call with query parameters
    const response = await fetch(`${baseUrl}/masters/cities?${params.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data; // Return the parsed JSON data
  } catch (error) {
    console.error("Error in getTopCitiesApi:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
