import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CardActionArea,
  Stack,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchUserMedia } from "../../api/common/fawApi";

const UserMedia = () => {
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadMediaData = async () => {
      try {
        const data = await fetchUserMedia();
        setMediaData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    loadMediaData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" sx={{ mt: 3 }}>
        {error}
      </Typography>
    );
  }

  const handleCardClick = (mediaId) => {
    navigate(`/media/${mediaId}`);
  };

  return (
    <Box sx={{ mt: 3, px: 2 }}>
      <Typography
        variant="h5"
        sx={{ mb: 3, fontWeight: "bold", color: "#3f51b5" }}
      >
        User Media
      </Typography>
      {mediaData.media && mediaData.media.length > 0 ? (
        <Grid container spacing={2}>
          {mediaData.media.map((media, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  boxShadow: 3,
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.05)" },
                  height: 480, // Fixed card height
                }}
                onClick={() => handleCardClick(media._id)}
              >
                <CardActionArea sx={{ height: "100%" }}>
                  {/* Render video or image accordingly */}
                  {media.media.mimetype.startsWith("video/") ? (
                    <CardMedia
                      component="video"
                      src={media.media.url}
                      controls
                      alt={media.media.filename}
                      sx={{
                        height: 350,
                        width: "100%",
                        objectFit: "contain", // Ensure the video fits within the card
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="video"
                      image={media.media.url}
                      alt={media.media.filename}
                      sx={{
                        height: 350,
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <CardContent sx={{ height: 200 }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ color: "#333", mb: 1 }}
                    >
                      {media.header || "Untitled"}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        flexWrap: "wrap", // Allow chips to wrap
                        mb: 1,
                      }}
                    >
                      {media.tags.slice(0, 1).map((tag, idx) => (
                        <Chip
                          key={idx}
                          label={tag}
                          size="small"
                          sx={{
                            backgroundColor: "#e0f7fa",
                            color: "#00796b",
                            fontWeight: "bold",
                          }}
                        />
                      ))}
                    </Stack>
                    <Typography
                      variant="body2"
                      sx={{ mt: 2 }}
                      color="text.secondary"
                    >
                      <strong>Likes:</strong> {media.likes} |{" "}
                      <strong>Shares:</strong> {media.shares} |{" "}
                      <strong>Saves:</strong> {media.saves}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" sx={{ mt: 2 }}>
          No media available.
        </Typography>
      )}
    </Box>
  );
};

export default UserMedia;
