import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Paper,
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { fetchByQuotationId } from "../../api/quotations/quotations";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";

const QuotationDetails = () => {
  const { id } = useParams();
  const [quoteDetails, setQuoteDetails] = useState(null);

  useEffect(() => {
    const fetchQuotationDetails = async () => {
      if (!id) return;
      try {
        const response = await fetchByQuotationId(id);
        setQuoteDetails(response);
      } catch (error) {
        console.error("Error fetching quotation details:", error);
      }
    };
    fetchQuotationDetails();
  }, [id]);

  if (!quoteDetails) return null;

  const {
    client,
    cvAccess,
    pricePerCVAccess,
    tcCVAccess,
    grossAmount,
    gst,
    totalCost,
    cvDownloadAccess,
    pricePerCVDownload,
    tcCVDownload,
    invites,
    pricePerInvite,
    tcInvites,
    jobPostAccess,
    pricePerJobPost,
    tcJobPostAccess,
  } = quoteDetails;

  const {
    organizationName,
    organizationAddress,
    contactNumber,
    email,
    organizationId,
  } = client;

  const renderInfoCard = (title, details) => (
    <Card elevation={2} sx={{ marginBottom: "1rem", borderRadius: "10px" }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#6A1B9A" }}>
          {title}
        </Typography>
        <Divider sx={{ marginBottom: "0.5rem" }} />
        {details.map(({ label, value }) => (
          <Typography
            key={label}
            variant="body2"
            sx={{ marginBottom: "0.3rem", color: "#555" }}
          >
            <strong>{label}:</strong> {value}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );

  return (
    <AdminDrawerList>
      <Paper
        elevation={3}
        sx={{
          padding: "2rem",
          borderRadius: "1rem",
          maxWidth: "900px",
          margin: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600",
            color: "#6A1B9A",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          Quotation Details
        </Typography>
        <Divider sx={{ marginBottom: "1.5rem" }} />

        {/* Client Information */}
        {renderInfoCard("Client Information", [
          { label: "Organization", value: organizationName },
          { label: "Organization ID", value: organizationId },
          { label: "Address", value: organizationAddress },
          { label: "Contact", value: contactNumber },
          { label: "Email", value: email },
        ])}

        {/* Services Pricing */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {renderInfoCard("CV Access Details", [
              { label: "CV Access", value: cvAccess },
              { label: "Price per CV", value: `${pricePerCVAccess} INR` },
              { label: "Total Cost", value: `${tcCVAccess} INR` },
            ])}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderInfoCard("CV Download Access Details", [
              { label: "CV Download Access", value: cvDownloadAccess },
              {
                label: "Price per Download",
                value: `${pricePerCVDownload} INR`,
              },
              { label: "Total Cost", value: `${tcCVDownload} INR` },
            ])}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderInfoCard("Invite Access Details", [
              { label: "Invites", value: invites },
              { label: "Price per Invite", value: `${pricePerInvite} INR` },
              { label: "Total Cost", value: `${tcInvites} INR` },
            ])}
          </Grid>

          <Grid item xs={12} sm={6}>
            {renderInfoCard("Job Post Access Details", [
              { label: "Job Post Access", value: jobPostAccess },
              { label: "Price per Job Post", value: `${pricePerJobPost} INR` },
              { label: "Total Cost", value: `${tcJobPostAccess} INR` },
            ])}
          </Grid>
        </Grid>

        {/* Cost Summary */}
        {renderInfoCard("Cost Summary", [
          { label: "Gross Amount", value: `${grossAmount} INR` },
          { label: "GST", value: `${gst} INR` },
          { label: "Total Cost", value: `${totalCost} INR` },
        ])}
      </Paper>
    </AdminDrawerList>
  );
};

export default QuotationDetails;
