import baseUrl from "../../config";

export const fetchRecruiterMedia = async () => {
  try {
    const response = await fetch(`${baseUrl}/media/all?userModel=Recruiter`);

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (err) {
    throw err; // Propagate the error to the caller
  }
};

export const fetchUserMedia = async () => {
  try {
    const response = await fetch(`${baseUrl}/media/all?userModel=User`);

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (err) {
    throw err; // Propagate the error to the caller
  }
};

export const fetchMediaById = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/fetch_media/${id}`);

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (err) {
    throw err; // Propagate the error to the caller
  }
};

export const getOtherMedia = async (id, userModel) => {
  try {
    const response = await fetch(
      `${baseUrl}/otherMedia/${id}?userModel=${userModel}`
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Return the fetched data
  } catch (err) {
    throw err; // Propagate the error to the caller
  }
};
