import React, { useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Avatar,
  Divider,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThumbUp, Share, Bookmark, Delete } from "@mui/icons-material";
import baseUrl from "../../config";

const MediaDetails = ({ data }) => {
  const [media, setMedia] = useState(data);

  const handleBlockRelease = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/blockReleaseMedia/${media._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMedia({ ...media, isBlocked: !media.isBlocked }); // Toggle the block status
        alert(result.message);
      } else {
        alert(result.message || "Failed to block/release media.");
      }
    } catch (error) {
      console.error("Error blocking/releasing media:", error);
      alert("An error occurred while blocking/releasing the media.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${baseUrl}/media/${media._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        // You can implement a redirect or clear the component state here.
      } else {
        alert(result.message || "Failed to delete media.");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      alert("An error occurred while deleting the media.");
    }
  };

  if (!media) {
    return (
      <Typography variant="h6" color="error" textAlign="center">
        No media details available.
      </Typography>
    );
  }

  const {
    user,
    header,
    content,
    tags,
    likes,
    shares,
    saves,
    createdAt,
    updatedAt,
    isBlocked,
    isDeleted,
  } = media;

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "#fff",
        maxWidth: 800,
        margin: "0 auto",
        mt: 4,
      }}
    >
      {/* Header */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 600, fontSize: "1.5rem" }}
      >
        {header}
      </Typography>

      {/* User Details */}
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Avatar
          src={user?.profileImage}
          alt={user?.fullName}
          sx={{ width: 50, height: 50 }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {user?.fullName}
        </Typography>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Content */}
      <Typography
        variant="body1"
        paragraph
        sx={{ fontSize: 16, lineHeight: 1.5 }}
      >
        {content}
      </Typography>

      {/* Tags */}
      <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={`#${tag}`}
            variant="outlined"
            color="primary"
            sx={{
              cursor: "pointer",
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
                backgroundColor: "primary.main",
                color: "white",
              },
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          />
        ))}
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Stats */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Likes">
            <ThumbUp sx={{ mr: 1, color: "primary.main" }} />
          </Tooltip>
          <Typography variant="body1">{likes}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Shares">
            <Share sx={{ mr: 1, color: "primary.main" }} />
          </Tooltip>
          <Typography variant="body1">{shares}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Saves">
            <Bookmark sx={{ mr: 1, color: "primary.main" }} />
          </Tooltip>
          <Typography variant="body1">{saves >= 0 ? saves : "N/A"}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Timestamps */}
      <Box>
        <Typography variant="body2" color="textSecondary">
          Created At: {new Date(createdAt).toLocaleString()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Updated At: {new Date(updatedAt).toLocaleString()}
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Action Buttons */}
      <Box display="flex" gap={2} justifyContent="center">
        <Tooltip title={isBlocked ? "Release Media" : "Block Media"}>
          <IconButton
            color={isBlocked ? "success" : "error"}
            aria-label="release"
            onClick={handleBlockRelease}
            sx={{
              padding: 1,
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{ textTransform: "none" }}
            >
              {isBlocked ? "Release" : "Block"}
            </Button>
          </IconButton>
        </Tooltip>

        {!isDeleted ? (
          <Tooltip title="Delete Media">
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={handleDelete}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              Media Marked Deleted!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MediaDetails;
