import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  CircularProgress,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { fetchMediaById } from "../../api/common/fawApi"; // API call to fetch by id
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import { ToastContainer } from "react-toastify";
import VideoDisplay from "./VideoDisplay";
import MediaDetails from "./MediaDetails";
import MediaScroll from "./UserMediaScroll";

const Media = () => {
  const { id } = useParams(); // Get media id from the URL
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    const loadMediaById = async () => {
      try {
        const data = await fetchMediaById(id, { signal: controller.signal }); // Fetch media by id
        if (data) {
          setMedia(data);
        } else {
          setError("Media not found for the given ID.");
        }
        setLoading(false);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(
            err.response?.data?.message || err.message || "An error occurred"
          );
          setLoading(false);
        }
      }
    };

    loadMediaById();

    return () => controller.abort(); // Cleanup on component unmount
  }, [id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Center spinner vertically across the viewport
        }}
      >
        <CircularProgress size={50} sx={{ color: "primary.main" }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        variant="h6"
        color="error"
        sx={{ mt: 3, textAlign: "center" }}
      >
        {error}
      </Typography>
    );
  }

  if (!media) {
    return (
      <Typography
        variant="h6"
        color="error"
        sx={{ mt: 3, textAlign: "center" }}
      >
        No media found for the given ID.
      </Typography>
    );
  }

  return (
    <>
      <AdminDrawerList>
        <Container
          maxWidth="lg"
          sx={{
            padding: 2,
            backgroundColor: "#f9f9f9",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            minHeight: "100vh", // Ensure container takes full height
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={1} sx={{ flexGrow: 1 }}>
            {/* Main Content */}
            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: "8px",
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Grid container>
                    {/* Video Section */}
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <VideoDisplay data={media} />
                      </Box>
                    </Grid>

                    {/* Details Section */}
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          height: "100%", // Ensure content section stretches to fill space
                        }}
                      >
                        <MediaDetails data={media} />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Placeholder for Video Carousel */}
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  marginTop: 4,
                  textAlign: "center",
                }}
              >
                <MediaScroll data={media} />
              </Box>
            </Grid>
          </Grid>

          {/* Toast Container */}
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            draggable
            theme="colored"
          />
        </Container>
      </AdminDrawerList>
    </>
  );
};

export default Media;
