import React, { useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import UserMedia from "./UserMedia";
import RecruiterMedia from "./RecruiterMedia";

const MainFaw = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <AdminDrawerList>
      <Typography variant="h5" sx={{ mb: 2 }}>
        FAW
      </Typography>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="FAW Tabs">
        <Tab label="User Media" />
        <Tab label="Recruiter Media" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {selectedTab === 0 && <UserMedia />}
        {selectedTab === 1 && <RecruiterMedia />}
      </Box>
    </AdminDrawerList>
  );
};

export default MainFaw;
