import React from "react";
import VideoSlider from "../../ResusableComponents/VideoSlider";

const JobVideoCards = ({ videodata }) => {
  console.log("videodata", videodata);

  let media = [];

  // If videodata is an array, extract unique URLs from each video object.
  if (Array.isArray(videodata)) {
    media = Array.from(
      new Set(
        videodata
          .filter((video) => video.jobVideoUrl?.url) // Ensure the URL exists
          .map((video) => video.jobVideoUrl.url)
      )
    ).map((src) => ({
      type: "video",
      src,
    }));
  }
  // If videodata is an object, check for a valid URL and create a single media item.
  else if (videodata && typeof videodata === "object" && videodata.jobVideoUrl?.url) {
    media = [
      {
        type: "video",
        src: videodata.jobVideoUrl.url,
      },
    ];
  }

  return <VideoSlider media={media} />;
};

export default JobVideoCards;
