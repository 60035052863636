import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  CircularProgress,
  IconButton
} from "@mui/material";
import { styled } from "@mui/system";

// Material UI Icons
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BusinessIcon from "@mui/icons-material/Business";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import baseUrl from "../../../config";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#a1a4b5",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "14px",
  textAlign: "justify",
  fontFamily: "Poppins, sans-serif",
}));

// Mapping for job type conversion
const jobTypeMap = {
  fullTime: "Full-Time",
  partTime: "Part-Time",
  // Add more mappings as needed
};

const ScrollContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  width: "700px", // Fixed width for the scroll container
  whiteSpace: "nowrap",
  padding: "8px 0",
  gap: "12px",
  "&::-webkit-scrollbar": {
    height: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#a1a4b5",
    borderRadius: "10px",
  },
});

const RecruiterCard = styled(Card)({
  minWidth: "180px", // Each card width
  maxWidth: "180px",
  borderRadius: "10px",
  flex: "0 0 auto",
});


const ArrowButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: "#fff",
  borderRadius: "50%",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});

const JobDescription = ({ jobDetails }) => {
  const [otherRecruiters, setOtherRecruiters] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchOtherRecruiters = async () => {
      if (jobDetails?.postedBy?.organization && jobDetails?.postedBy?._id) {
        try {
          const response = await fetch(
            `${baseUrl}/organization/otherRecruiters/${jobDetails.postedBy.organization}/${jobDetails.postedBy._id}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch recruiters");
          }
          const data = await response.json();
          setOtherRecruiters(data.recruiters || []);
        } catch (error) {
          console.error("Error fetching other recruiters:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchOtherRecruiters();
  }, [jobDetails]);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 180; // The width of each card
      const newScrollPosition =
        scrollRef.current.scrollLeft + direction * scrollAmount;
      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <StyledPaper elevation={3}>
      <Box>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={3}>
          {/* Job Description */}
          <Grid item xs={12}>
            <SectionTitle variant="h6">Job Description</SectionTitle>
            <StyledTypography variant="body1" component="div">
              <ul style={{ margin: 0, paddingLeft: "20px" }}>
                <li>{jobDetails.jobRole}</li>
              </ul>
            </StyledTypography>
          </Grid>

          {/* Job Posted On */}
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Job Posted On:{" "}
                {jobDetails?.createdAt
                  ? new Date(jobDetails.postDate).toLocaleDateString()
                  : "N/A"}
              </StyledTypography>
            </Box>
          </Grid>

          {/* Preferred Skills & Job Location side by side */}
          <Grid item xs={12} container spacing={2}>
            {/* Preferred Skills */}
            <Grid item xs={12} sm={6}>
              <SectionTitle variant="h6">Preferred Skills</SectionTitle>
              {jobDetails?.skillPreferences &&
              jobDetails.skillPreferences.length > 0 ? (
                <List dense>
                  {jobDetails.skillPreferences.map((skill, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <WorkOutlineIcon
                          fontSize="small"
                          sx={{ color: "#a1a4b5" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={skill}
                        primaryTypographyProps={{
                          variant: "body1",
                          sx: {
                            color: "#a1a4b5",
                            fontFamily: "Poppins, sans-serif",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <StyledTypography variant="body1">N/A</StyledTypography>
              )}
            </Grid>

            {/* Job Location */}
            <Grid item xs={12} sm={6}>
              <SectionTitle variant="h6">Job Location</SectionTitle>
              {jobDetails?.jobLocation && jobDetails.jobLocation.length > 0 ? (
                <List dense>
                  {jobDetails.jobLocation.map((location, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <LocationOnIcon
                          fontSize="small"
                          sx={{ color: "#a1a4b5" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={location}
                        primaryTypographyProps={{
                          variant: "body1",
                          sx: {
                            color: "#a1a4b5",
                            fontFamily: "Poppins, sans-serif",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <StyledTypography variant="body1">N/A</StyledTypography>
              )}
            </Grid>
          </Grid>

          {/* Job Type & Salary */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <WorkOutlineIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Job Type:{" "}
                {jobTypeMap[jobDetails?.jobType] || jobDetails?.jobType}
              </StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <MonetizationOnIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Salary: From {jobDetails?.ctc} per year
              </StyledTypography>
            </Box>
          </Grid>

          {/* Department & Industry */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <BusinessIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Department: {jobDetails?.department}
              </StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <BusinessIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Industry: {jobDetails?.industry}
              </StyledTypography>
            </Box>
          </Grid>

          {/* Experience Type & Required Experience */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Experience Type: {jobDetails?.experienceType}
              </StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Required Experience: {jobDetails?.requiredExperience}
              </StyledTypography>
            </Box>
          </Grid>

          {/* Required Education */}
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <SchoolIcon
                fontSize="small"
                sx={{ marginRight: 1, color: "#a1a4b5" }}
              />
              <StyledTypography variant="body1">
                Required Education: {jobDetails?.requiredEducation}
              </StyledTypography>
            </Box>
          </Grid>

          {/* Open Positions (if available) */}
          {jobDetails?.noOfPostionsOpen && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <StyledTypography variant="body1">
                  Open Positions: {jobDetails?.noOfPostionsOpen}
                </StyledTypography>
              </Box>
            </Grid>
          )}

          <Divider sx={{ marginBottom: 2, marginTop: 2, width: "100%" }} />

          {/* Recruiter Contact Information */}
          {jobDetails?.onBehalfOfOrganization === true ? (
            <Grid item xs={12}>
              <StyledTypography variant="body1">
                This job was posted on behalf of the organization by the Admin.
              </StyledTypography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <SectionTitle variant="subtitle1">
                Speak with the Recruiter:
              </SectionTitle>
              {jobDetails?.postedBy ? (
                <>
                  <Box display="flex" alignItems="center" mb={0.5}>
                    <PersonIcon
                      fontSize="small"
                      sx={{ marginRight: 1, color: "#a1a4b5" }}
                    />
                    <StyledTypography variant="body1">
                      Name: {jobDetails.postedBy.rcName || "N/A"}
                    </StyledTypography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={0.5}>
                    <PersonIcon
                      fontSize="small"
                      sx={{ marginRight: 1, color: "#a1a4b5" }}
                    />
                    <StyledTypography variant="body1">
                      Email: {jobDetails.postedBy.rcEmail || "N/A"}
                    </StyledTypography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={0.5}>
                    <PersonIcon
                      fontSize="small"
                      sx={{ marginRight: 1, color: "#a1a4b5" }}
                    />
                    <StyledTypography variant="body1">
                      Role: {jobDetails.postedBy.role || "N/A"}
                    </StyledTypography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <PersonIcon
                      fontSize="small"
                      sx={{ marginRight: 1, color: "#a1a4b5" }}
                    />
                    <StyledTypography variant="body1">
                      Phone: {jobDetails.postedBy.rcPhone || "N/A"}
                    </StyledTypography>
                  </Box>
                </>
              ) : (
                <StyledTypography variant="body1">
                  Recruiter details are not available.
                </StyledTypography>
              )}
            </Grid>
          )}
        </Grid>
        {/* Vertical Divider */}
        <Grid item xs={12} sm={0.5}>
          <Divider
            orientation="vertical"
            sx={{ height: "100%", width: "1px" }}
          />
        </Grid>
        {/* Vertical Divider */}
        <Grid item xs={12}>
          <Divider sx={{ marginY: 2 }} />
        </Grid>

        {/* Other Recruiters (Horizontal Scroll with Arrow Buttons) */}
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight="bold">
          Other Recruiters in the Organization:
        </Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : otherRecruiters.length > 0 ? (
          <Box sx={{ position: "relative" }}>
            <ArrowButton
              sx={{ left: "0" }}
              onClick={() => scroll(-1)} // Scroll left
            >
              <ArrowBackIcon />
            </ArrowButton>

            <ScrollContainer ref={scrollRef}>
              {otherRecruiters.map((recruiter) => (
                <RecruiterCard key={recruiter._id}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={1}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 1, color: "#a1a4b5" }} />
                      <Typography variant="body1" fontWeight="bold">
                        {recruiter.rcName}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      {recruiter.rcEmail}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {recruiter.rcPhone}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      Role: {recruiter.role}
                    </Typography>
                  </CardContent>
                </RecruiterCard>
              ))}
            </ScrollContainer>

            <ArrowButton
              sx={{ right: "0" }}
              onClick={() => scroll(1)} // Scroll right
            >
              <ArrowForwardIcon />
            </ArrowButton>
          </Box>
        ) : (
          <Typography variant="body1">No other recruiters found.</Typography>
        )}
      </Grid>
      </Box>
    </StyledPaper>
  );
};

export default JobDescription;
