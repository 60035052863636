import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Divider,
  Typography,
  Collapse,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DescriptionIcon from "@mui/icons-material/Description";
import WorkIcon from "@mui/icons-material/Work";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteIcon from "@mui/icons-material/Delete";
import Vacanzi from "../../assets/vacanzi.png";
import "./SideDrawer.css"; // Import your custom CSS file
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const drawerWidth = 260;

const SideDrawer = ({ isDrawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const [openReports, setOpenReports] = useState(false);
  const [openPaymments, setOpenPaymments] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const [openPromoCodes, setOpenPromoCodes] = useState(false);
  const [openContentManagement, setOpenContentManagement] = useState(false);

  const handlePaymments = () => setOpenPaymments(!openPaymments);
  const handleSupport = () => setOpenSupport(!openSupport);
  const handlePromoCodes = () => setOpenPromoCodes(!openPromoCodes);
  const handleReports = () => setOpenReports(!openReports);
  const handleContentManagement = () =>
    setOpenContentManagement(!openContentManagement);

  const handleNavigation = (path) => {
    navigate(path); // Navigate without closing menus
  };

  const userRole = localStorage.getItem("role");

  return (
    <Drawer
      variant="permanent"
      open={isDrawerOpen}
      sx={{
        width: isDrawerOpen ? drawerWidth : 64,
        flexShrink: 0,
        height: "100vh",
        whiteSpace: "nowrap",
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        [`& .MuiDrawer-paper`]: {
          width: isDrawerOpen ? drawerWidth : 64,
          boxSizing: "border-box",
          overflowX: "hidden",
          bgcolor: "#121212",
          color: "white",
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        },
      }}
    >
      <IconButton
        onClick={toggleDrawer}
        sx={{ color: "white", display: "flex", alignItems: "center" }}
      >
        {isDrawerOpen ? (
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.50rem",
              fontWeight: "bold",
              height: "80px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <img
              src={Vacanzi}
              height={50}
              width={50}
              alt="vacanzi"
              style={{ marginRight: "2px" }}
            />
            Vacanzi Admin
          </Typography>
        ) : (
          <img src={Vacanzi} height={50} width={50} alt="vacanzi" />
        )}
      </IconButton>

      <Divider />

      <ul className="side-drawer-list">
        {/* Dashboard Item */}
        {userRole !== "data_analyst" && (
          <li className="list-item">
            <DashboardIcon className="icon" fontSize="small" />
            {isDrawerOpen && (
              <span onClick={() => handleNavigation("/dashboard")}>
                Dashboard
              </span>
            )}
          </li>
        )}
        {/* Employers Menu */}
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => handleNavigation("/employer/employersList")}
          >
            <BadgeIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Employers</span>}
          </li>
        )}
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => handleNavigation("/JobPostings")}
          >
            <WorkIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Job Postings</span>}
          </li>
        )}

        {/* JobSeekers Item */}
        <li
          className="list-item"
          onClick={() => {
            handleNavigation("/jobseekers");
          }}
        >
          <BusinessIcon className="icon" fontSize="small" />
          {isDrawerOpen && <span>JobSeekers</span>}
        </li>
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => {
              handleNavigation("/faw");
            }}
          >
            <PlayCircleOutlineIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>FAW</span>}
          </li>
        )}

        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => {
              handleNavigation("/subscriptions");
            }}
          >
            <AccountBalanceWalletIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Subscription</span>}
          </li>
        )}

        {userRole !== "data_analyst" && (
          <li className="list-item" onClick={handlePaymments}>
            <CurrencyRupeeIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Payments</span>}
            {isDrawerOpen &&
              (openPaymments ? (
                <ExpandLessIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ) : (
                <ExpandMoreIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ))}
          </li>
        )}
        {isDrawerOpen && (
          <Collapse
            in={openPaymments}
            timeout={{ enter: 500, exit: 300 }}
            unmountOnExit
          >
            <ul className="sub-list">
              {/* IT Section */}
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/employerPayments");
                }}
              >
                Employer Payments
              </li>
              {/* NON IT Section */}
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/jobSeekerPayments");
                }}
              >
                JobSeeker Payments
              </li>
            </ul>
          </Collapse>
        )}
        {userRole !== "data_analyst" && (
          <li className="list-item" onClick={handleSupport}>
            <SupportAgentIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Support</span>}
            {isDrawerOpen &&
              (openSupport ? (
                <ExpandLessIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ) : (
                <ExpandMoreIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ))}
          </li>
        )}
        {/* Submenu for IT and NON IT */}
        {isDrawerOpen && (
          <Collapse
            in={openSupport}
            timeout={{ enter: 500, exit: 300 }}
            unmountOnExit
          >
            <ul className="sub-list">
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/employer/support");
                }}
              >
                Employer Support
              </li>
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/jobSeeker/support");
                }}
              >
                JobSeeker Support
              </li>
            </ul>
          </Collapse>
        )}
        <li className="list-item" onClick={handleReports}>
          <BarChartIcon className="icon" fontSize="small" />
          {isDrawerOpen && <span>Reports</span>}
          {isDrawerOpen &&
            (openReports ? (
              <ExpandLessIcon
                fontSize="small"
                sx={{ color: "white" }}
                className="expand-icon"
              />
            ) : (
              <ExpandMoreIcon
                fontSize="small"
                sx={{ color: "white" }}
                className="expand-icon"
              />
            ))}
        </li>
        {isDrawerOpen && (
          <Collapse
            in={openReports}
            timeout={{ enter: 500, exit: 300 }}
            unmountOnExit
          >
            <ul className="sub-list">
              {/* IT Section */}
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/reports/jobseekers");
                }}
              >
                JobSeeker Reports
              </li>
              {/* NON IT Section */}
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/reports/employers");
                }}
              >
                Employer Reports
              </li>
            </ul>
          </Collapse>
        )}
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => {
              handleNavigation("/roles");
            }}
          >
            <LocalOfferIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Roles</span>}
          </li>
        )}

        {userRole !== "data_analyst" && (
          <li className="list-item" onClick={handlePromoCodes}>
            <LocalOfferIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>PromoCodes</span>}
            {isDrawerOpen &&
              (openPromoCodes ? (
                <ExpandLessIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ) : (
                <ExpandMoreIcon
                  fontSize="small"
                  sx={{ color: "white" }}
                  className="expand-icon"
                />
              ))}
          </li>
        )}
        {/* Submenu for IT and NON IT */}
        {isDrawerOpen && (
          <Collapse
            in={openPromoCodes}
            timeout={{ enter: 500, exit: 300 }}
            unmountOnExit
          >
            <ul className="sub-list">
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/employer/promoCodes");
                }}
              >
                Employer Promocodes
              </li>
              <li
                className="sub-list-item"
                onClick={() => {
                  handleNavigation("/jobSeeker/promoCodes");
                }}
              >
                JobSeeker Promocodes
              </li>

            </ul>
          </Collapse>
        )}
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => {
              handleNavigation("/quotations");
            }}
          >
            <DescriptionIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Quotations</span>}
          </li>
        )}
        {userRole !== "data_analyst" && (
          <li className="list-item" onClick={handleContentManagement}>
            <SettingsApplicationsIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Content Management</span>}
          </li>
        )}
        {userRole !== "data_analyst" && (
          <li
            className="list-item"
            onClick={() => {
              handleNavigation("/deletedEmployers");
            }}
          >
            <DeleteIcon className="icon" fontSize="small" />
            {isDrawerOpen && <span>Deleted</span>}
          </li>
        )}
      </ul>
    </Drawer>
  );
};

export default SideDrawer;
