import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Tabs, Tab, Button } from "@mui/material";
import AdminDrawerList from "../../../components/Drawer/AdminDrawerList";
import EmployerDetails from "./EmployerDetails";
import EmployerJobPostedTab from "./EmployerJobsPostedTab";

const EmployerTabs = () => {
  const [activeTab, setActiveTab] = useState("EmployerDetails");
  const { id } = useParams(); // Assuming you pass the ID via route params
  
  const navigate = useNavigate();

  const handlePostJobClick = () => {
    navigate(`/employer/createJobPost?organizationId=${id}`); 
  };
  

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
  };

  const postJobButtonStyles = {
    color: "white",
    borderColor: "white",
    backgroundColor: "#f2426d",
    padding: "8px 16px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#e03a5c",
    },
  };

  return (
    <AdminDrawerList>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Align the button to the right
          mb: 2, // Add margin-bottom for spacing
        }}
      >
        <Button variant="contained" sx={postJobButtonStyles} onClick={handlePostJobClick}>
          Post a Job
        </Button>
      </Box>

      <Box sx={{ display: "flex", mt: 2, mb: 5 }}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          textColor="secondary"
          indicatorColor="white"
          sx={{
            "& .MuiTab-root": {
              fontWeight: 600,
              textTransform: "none",
              borderRadius: "8px",
              bgcolor: "#f5f5f5",
              px: 12, // Add padding inside the tabs for better aesthetics
              "&:not(:last-child)": {
                mr: 0, // Remove margin/gap between tabs
              },
              "&.Mui-selected": {
                bgcolor: "green",
                color: "white",
              },
            },
          }}
        >
          <Tab value="EmployerDetails" label="Employer Details" />
          <Tab value="Job Posted" label="Job Posted" />
          <Tab value="DataBase" label="Database" />
        </Tabs>
      </Box>

      {/* Dynamic Content */}
      <Box>
        {activeTab === "EmployerDetails" && (
          <EmployerDetails organizationId={id} />
        )}
        {activeTab === "Job Posted" && (
          <EmployerJobPostedTab organizationId={id} />
        )}
        {activeTab === "DataBase" && (
          <EmployerJobPostedTab organizationId={id} />
        )}
      </Box>
    </AdminDrawerList>
  );
};

export default EmployerTabs;
