import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import EmployerDataBase from "../pages/Employers/InformationTechnology/EmployerDataBase";
import EmployerNonITJobPosted from "../pages/Employers/NonInformationTechnology/EmployerNonITJobPosted";
import EmployerNonITDatabase from "../pages/Employers/NonInformationTechnology/EmployerNonITDatabase";
import EmployerJobDetails from "../pages/Employers/InformationTechnology/EmployerJobDetails/EmployerJobDetails";
import JobSeekers from "../pages/JobSeekers/JobSeekers";
import JobSeekerPersonalProfile from "../pages/JobSeekers/JobSeekerPersonalProfile";
import EmployerSubscription from "../pages/Subscription/EmployerSubscription/EmployerSubscription";
import JobSeekerSubscription from "../pages/Subscription/JobSeekerSubscription/JobSeekerSubscription";
import Login from "../pages/Auth/Login/Login";
import Quotations from "../pages/Quotations/Quotations";
import DeletedEmployer from "../pages/Deleted/DeletedEmployers/DeletedEmployer";
import EmployerList from "../pages/Employers/Employers/EmployerList";
import EmployerPayments from "../pages/Payments/EmployerPayments";
import JobSeekerPayments from "../pages/Payments/JobSeekerPayments";
import EmployerSubscriptionDetails from "../pages/Subscription/EmployerSubscription/EmployerSubscriptionDetails";
import JobSeekerSubscriptionDetails from "../pages/Subscription/JobSeekerSubscription/JobSeekerSubscriptionDetails";
import JobSeekerReports from "../pages/Reports/JobSeekerReports";
import EmployerReports from "../pages/Reports/EmployerReports";
import Roles from "../pages/Roles/Roles";
import AddRoles from "../pages/Roles/AddRoles";
import ProtectedRoute from "../utils/ProtectedRoute";
import CreateEmployers from "../pages/Employers/Employers/CreateEmployers";
import EmployerTabs from "../pages/Employers/Employers/EmployerTabs";
import JobPostings from "../pages/Employers/Employers/JobPostings/JobPostings";
import JobSeekerInternal from "../pages/JobSeekers/JobSeekerInernal";
import Subscriptions from "../pages/Subscription/Subscriptions";
import AddSubscriptions from "../pages/Subscription/AllSubscriptions/AddSubscriptions";
import AllSubscriptionDetails from "../pages/Subscription/AllSubscriptions/AllSubscriptionDetails";
import AddQuotations from "../pages/Quotations/AddQuotations";
import QuotationDetails from "../pages/Quotations/QuotationDetails";
import Employersupport from "../pages/Support/EmployerSupport/Employersupport";
import JobSeekerSupport from "../pages/Support/JobSeekerSupport/JobSeekerSupport";
import AdminProfile from "../pages/Profile/AdminProfile";
import JobSeekerPromoCodes from "../pages/PromoCodes/JobSeekerPromoCodes/JobSeekerPromoCodes";
import AddJobSeekerPromo from "../pages/PromoCodes/JobSeekerPromoCodes/AddJobSeekerPromo";
import EmployerPromoCodes from "../pages/PromoCodes/EmployerPromoCodes/EmployerPromoCodes";
import AddEmployerPromo from "../pages/PromoCodes/EmployerPromoCodes/AddEmployerPromo";
import ViewEmployerPromoDetails from "../pages/PromoCodes/EmployerPromoCodes/ViewEmployerPromoDetails";
import EmployerInvoice from "../pages/Payments/EmployerInvoice";
import JobSeekerInvoice from "../pages/Payments/JobSeekerInvoice";
import EmployerJobPostPage from "../pages/Employers/Employers/JobPostings/EmployerJobPostPage.jsx";
import MainFaw from "../pages/FAW/MainFaw.jsx";
import Media from "../pages/FAW/Media.jsx";
import EmployerSubscriptionForm from "../pages/Subscription/EmployerSubscription/EmployerSubscriptionForm.jsx";

const RoutingComponent = () => {
  return (
    <Router>
      <Routes>
        {/* Login */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobseekers/internal"
          element={
            <ProtectedRoute>
              <JobSeekerInternal />
            </ProtectedRoute>
          }
        />

        {/* Employers */}
        <Route
          path="/employer/employersList"
          element={
            <ProtectedRoute>
              <EmployerList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/addEmployer"
          element={
            <ProtectedRoute>
              <CreateEmployers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/editEmployer/:id"
          element={
            <ProtectedRoute>
              <CreateEmployers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/organizationTabs/:id"
          element={
            <ProtectedRoute>
              <EmployerTabs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/createJobPost"
          element={
            <ProtectedRoute>
              <EmployerJobPostPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/JobPostings"
          element={
            <ProtectedRoute>
              <JobPostings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/ITDatabase"
          element={
            <ProtectedRoute>
              <EmployerDataBase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/NonITJobPosted"
          element={
            <ProtectedRoute>
              <EmployerNonITJobPosted />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/NonITDatabase"
          element={
            <ProtectedRoute>
              <EmployerNonITDatabase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/employerJobDetails"
          element={
            <ProtectedRoute>
              <EmployerJobDetails />
            </ProtectedRoute>
          }
        />

        {/* JobSeekers */}
        <Route
          path="/jobseekers"
          element={
            <ProtectedRoute>
              <JobSeekers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobseeker/personalProfile"
          element={
            <ProtectedRoute>
              <JobSeekerPersonalProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobseeker/personalProfile/:id"
          element={
            <ProtectedRoute>
              <JobSeekerPersonalProfile />
            </ProtectedRoute>
          }
        />
        {/* FAW */}
        <Route
          path="/faw"
          element={
            <ProtectedRoute>
              <MainFaw />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media/:id"
          element={
            <ProtectedRoute>
              <Media />
            </ProtectedRoute>
          }
        />
        {/* Subscription */}

        <Route
          path="/subscriptions"
          element={
            <ProtectedRoute>
              <Subscriptions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/subscriptions/createSubscriptions"
          element={
            <ProtectedRoute>
              <AddSubscriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions/editSubscriptions/:id"
          element={
            <ProtectedRoute>
              <AddSubscriptions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions/viewSubscriptionDetails/:id"
          element={
            <ProtectedRoute>
              <AllSubscriptionDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/subscription"
          element={
            <ProtectedRoute>
              <EmployerSubscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/subscriptionDetails"
          element={
            <ProtectedRoute>
              <EmployerSubscriptionDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/subscriptionform"
          element={
            <ProtectedRoute>
              <EmployerSubscriptionForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobSeeker/subscription"
          element={
            <ProtectedRoute>
              <JobSeekerSubscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobSeeker/subscriptionDetails"
          element={
            <ProtectedRoute>
              <JobSeekerSubscriptionDetails />
            </ProtectedRoute>
          }
        />

        {/* Support */}

        <Route
          path="/employer/support"
          element={
            <ProtectedRoute>
              <Employersupport />
            </ProtectedRoute>
          }
        />

        <Route
          path="/jobSeeker/support"
          element={
            <ProtectedRoute>
              <JobSeekerSupport />
            </ProtectedRoute>
          }
        />

        {/* Promocodes */}
        <Route
          path="/jobSeeker/promoCodes"
          element={
            <ProtectedRoute>
              <JobSeekerPromoCodes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobSeeker/addPromocodes"
          element={
            <ProtectedRoute>
              <AddJobSeekerPromo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/promoCodes"
          element={
            <ProtectedRoute>
              <EmployerPromoCodes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/addPromocodes"
          element={
            <ProtectedRoute>
              <AddEmployerPromo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employer/editPromocodes/:id"
          element={
            <ProtectedRoute>
              <AddEmployerPromo />
            </ProtectedRoute>
          }
        />

        <Route
          path="/employer/viewPromocodes/:id"
          element={
            <ProtectedRoute>
              <ViewEmployerPromoDetails />
            </ProtectedRoute>
          }
        />

        {/* Quotations */}
        <Route
          path="/quotations"
          element={
            <ProtectedRoute>
              <Quotations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-quotations"
          element={
            <ProtectedRoute>
              <AddQuotations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-quotation/:id"
          element={
            <ProtectedRoute>
              <AddQuotations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-quotation/:id"
          element={
            <ProtectedRoute>
              <QuotationDetails />
            </ProtectedRoute>
          }
        />

        {/* Payments */}
        <Route
          path="/employerPayments"
          element={
            <ProtectedRoute>
              <EmployerPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employerPayments/invoice/:invoiceId"
          element={
            <ProtectedRoute>
              <EmployerInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobSeekerPayments"
          element={
            <ProtectedRoute>
              <JobSeekerPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobSeekerPayments/invoice/:invoiceId"
          element={
            <ProtectedRoute>
              <JobSeekerInvoice />
            </ProtectedRoute>
          }
        />

        {/* Reports */}
        <Route
          path="/reports/jobseekers"
          element={
            <ProtectedRoute>
              <JobSeekerReports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/employers"
          element={
            <ProtectedRoute>
              <EmployerReports />
            </ProtectedRoute>
          }
        />

        {/* Roles */}
        <Route
          path="/roles"
          element={
            <ProtectedRoute>
              <Roles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-roles"
          element={
            <ProtectedRoute>
              <AddRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-roles/:id"
          element={
            <ProtectedRoute>
              <AddRoles />
            </ProtectedRoute>
          }
        />

        {/* Deleted */}
        <Route
          path="/deletedEmployers"
          element={
            <ProtectedRoute>
              <DeletedEmployer />
            </ProtectedRoute>
          }
        />

        <Route
          path="/adminProfile"
          element={
            <ProtectedRoute>
              <AdminProfile />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutingComponent;
