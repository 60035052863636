import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import {fetchJobSeekerPaymentData } from "../../api/employer/payments";
import CustomSnackbar from "../../components/ResusableComponents/CustomSnackbar";
import SkeletonTable from "../../components/Tables/SkeletonTable";
import SeekersTable from "../../components/Tables/SeekersTable";

const JobSeekerPayments = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/jobSeekerPayments/invoice/${id}`);
  };
  
  // Dynamically create columns based on paymentData keys
  const columns = [
    {
      field: "siNo",
      headerName: "S.No",
      minWidth: 50,
      headerClassName: "data-grid-header",
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "userName",
      headerName: "JobSeeker Name",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleClick(params.row.id)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "userEmail",
      headerName: "JobSeeker Email",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "userPhone",
      headerName: "JobSeeker Phone",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      headerClassName: "data-grid-header",
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "gstAmount",
      headerName: "GST Amount",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "cgstAmount",
      headerName: "CGST Amount",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "sgstAmount",
      headerName: "SGST Amount",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "amountWithoutGst",
      headerName: "Amount Without GST",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "invoiceId",
      headerName: "Invoice ID",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "tier",
      headerName: "Tier",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "subscriptionFee",
      headerName: "Subscription Fee",
      minWidth: 250,
      headerClassName: "data-grid-header",
    },
    {
      field: "subscribedOn",
      headerName: "Subscribed On",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "validUntil",
      headerName: "Valid Until",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 250,
      headerClassName: "data-grid-header",
      renderCell: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      minWidth: 100,
      headerClassName: "data-grid-header",
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
    
  ];

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const paymentDetails = await fetchJobSeekerPaymentData();
        setPaymentData(paymentDetails); // Assuming the response data will be in the correct format
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
        setSnackbarSeverity("error");
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) {
    return (
      <AdminDrawerList>
        <SkeletonTable />
      </AdminDrawerList>
    );
  }

  return (
    <AdminDrawerList>
      <SeekersTable columns={columns} rows={paymentData} />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </AdminDrawerList>
  );
};

export default JobSeekerPayments;
