import React from "react";
import { Card, CardMedia, Typography, Box } from "@mui/material";

const VideoDisplay = ({ data }) => {
  return (
    <Card
      sx={{
        borderRadius: 4,
        position: "relative",
      }}
    >
      {data?.media ? (
        <CardMedia
          controls
          component="video"
          image={data?.media?.url}
          loop
          alt={data?.media?.filename}
          sx={{
            borderRadius: 4,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          autoPlay
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#f0f0f0",
            borderRadius: 4,
          }}
        >
          <Typography variant="body1" color="textSecondary">
            No Media found
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          left: 16,
          color: "white",
          fontWeight: "bold",
          p: 1,
          borderRadius: 2,
        }}
      >
        {/* <Typography
            //   sx={
            //     video?.jobVideoUrl
            //       ? { color: "white", fontWeight: "bold" }
            //       : { color: "black", fontWeight: "bold" }
            //   }
            >
              dummy Developer
            </Typography> */}
      </Box>
    </Card>
  );
};

export default VideoDisplay;
