import { z } from "zod";

// Zod schema for job post validation
const jobPostSchema = z.object({
  jobTitle: z.string().min(1, "Job title is required"),
  jobLocation: z.string().min(1, "Job city is required"),
  jobRole: z.string().min(1, "Role description is required"),
  educationRequired: z.string().min(1, "Education is required"),
  experienceRequired: z.string().min(1, "Experience is required"),
  ctc: z.string().min(1, "CTC range is required"),
  department: z.string().min(1, "Department is required"),
  industry: z.string().min(1, "Industry is required"),
  jobType: z.string().min(1, "Job type is required"),
  noOfPostionsOpen: z
    .number()
    .min(1, "At least 1 open position is required"), // Ensure at least 1 position
  skillPreferences: z
    .array(z.string())
    .nonempty("At least one skill is required"),
  walkIn: z.boolean(),
  revealSalary: z.boolean(),

  // Allow empty string OR a valid date format
  startDate: z.union([
    z.string().length(0), // Allow empty string ""
    z.string().refine((val) => !isNaN(Date.parse(val)), {
      message: "Invalid start date format",
    }),
  ]),

  endDate: z.union([
    z.string().length(0), // Allow empty string ""
    z.string().refine((val) => !isNaN(Date.parse(val)), {
      message: "Invalid end date format",
    }),
  ]),
});

export default jobPostSchema;
