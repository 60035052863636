import React from 'react';
import { Box, Typography, Grid, Paper, useTheme, Divider, useMediaQuery } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import JobDescription from './JobDescription';

const JobOverview = ({ jobDetails }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const generateChartData = () => {
        const today = new Date();
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        const last7Days = Array.from({ length: 7 }).map((_, index) => {
            const date = new Date();
            date.setDate(today.getDate() - (6 - index)); // Generate dates for the past 7 days
            return daysOfWeek[date.getDay()];
        });

        // Set all data points to 0, except the last day where viewCount is shown
        const dataset = Array(6).fill(0);
        dataset.push(jobDetails?.viewCount || 0); // Add viewCount to the last day

        return {
            labels: last7Days,
            datasets: [
                {
                    label: 'Clicks',
                    data: dataset,
                    borderColor: 'purple',
                    backgroundColor: 'rgba(128, 0, 128, 0.1)',
                    tension: 0.4,
                },
            ],
        };
    };

    const chartData = generateChartData();

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };


    return (
        <Paper elevation={0} sx={{ padding: 3, borderRadius: 2 }}>
           
            <Box>
              
            <Typography variant="h4" color="text.secondary">
                {jobDetails?.organizationId?.organizationName}
            </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >

                    <Box>
                        <Typography variant={isMobile ? 'body1' : 'h6'} fontWeight="bold">
                            {jobDetails?.jobTitle}
                        </Typography>

                    </Box>
                    {/* <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            textTransform: 'none',
                            color: 'green',
                            borderColor: 'green',
                            borderRadius: '6px',
                            mb: 2,
                        }}
                    >
                        Sponsor this job
                    </Button> */}
                </Box>

                {/* Graph Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mt: 2,
                    }}
                >
                    {/* Chart Container */}
                    <Box sx={{ flex: 2.5, height: isMobile ? 300 : 250, mb: isMobile ? 2 : 4 }}>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                            Clicks
                        </Typography>
                        <Box sx={{ height: '100%' }}>
                            <Line data={chartData} options={options} />
                        </Box>
                    </Box>

                    {/* Stats and Actions Container */}
                    <Box sx={{ flex: 1.5, ml: isMobile ? 0 : 2, textAlign: isMobile ? 'center' : 'left' }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Clicks This Week
                        </Typography>
                        <Typography variant="h4" color="primary" fontWeight="bold">
                            {jobDetails?.viewCount || 0} {/* Total clicks */}
                        </Typography>
                        {/* <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            sx={{
                                mb: 2,
                                textTransform: 'none',
                                width: '100%',
                            }}
                        >
                            Sponsor Job for More Clicks
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                textTransform: 'none',
                                borderRadius: '6px',
                                width: '100%',
                            }}
                            color="warning"
                        >
                            Improve Job Description
                        </Button> */}
                    </Box>
                </Box>

                {/* Divider (Optional for spacing on desktop) */}
                {!isMobile && <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}


                {/* Candidates Section */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Candidates
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Application Count
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    {jobDetails?.applicationCount}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Total View Count
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    {jobDetails?.viewCount}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Total Saved Count
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    {jobDetails?.savedCount}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Total Shortlisted
                                </Typography>
                                <Typography variant="h5" fontWeight="bold">
                                    {jobDetails?.shortListCount}
                                </Typography>
                            </Paper>
                        </Grid>

                    </Grid>
                    <Typography variant="subtitle2" color="text.secondary" textAlign="right" mt={1}>
                        0 Rejected
                    </Typography>
                    <JobDescription jobDetails={jobDetails} />
                </Box>
            </Box>
        </Paper>
    );
};

export default JobOverview;
