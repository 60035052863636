import React from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ShareIcon from "@mui/icons-material/Share";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

const JobDetailsCard = ({jobDetails}) => {  
  return (
    <Paper
      elevation={0}
      sx={{

        padding: 2,
        borderRadius: "8px",
      }}
    >
      {/* Edit Job Button */}
      <Button
        fullWidth
        variant="contained"
        sx={{
          backgroundColor: "#4A90E2",
          color: "#fff",
          textTransform: "none",
          fontWeight: "bold",
          borderRadius: "6px",
          mb: 2,
          "&:hover": {
            backgroundColor: "#4A90E2",
          },
        }}
      >
        Edit Job
      </Button>

      {/* Actions List */}
      <Box>
        {["Close Job"].map(
          (text) => (
            <Box
              key={text}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: "8px 0", cursor: "pointer" }}
            >
              <Typography variant="body2" sx={{ color: '#a1a4b5' }}>{text}</Typography>
              <IconButton size="small">
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Add Candidate Button */}
      {/* <Button
        fullWidth
        variant="outlined"
        sx={{
          textTransform: "none",
          color: "green",
          borderColor: "green",
          borderRadius: "6px",
          mb: 2,
        }}
      >
        Add Candidate
      </Button> */}

      {/* Job Stats */}
      <Box sx={{ mb: 2 }}>
      <Typography variant="body2" fontWeight='bold'>Job ID: <strong>{jobDetails?.jobId}</strong></Typography>
        <Typography variant="body2" fontWeight='bold'>Views: <strong>{jobDetails?.viewCount}</strong></Typography>
        <Typography variant="body2" fontWeight='bold'>
          Status: {jobDetails?.status}{" "}
          <Chip
            label="Pause"
            size="small"
            sx={{
              backgroundColor: "#e3f2fd",
              color: "#1976d2",
              fontWeight: "bold",
              ml: 1,
            }}
          />
        </Typography>
        <Typography variant="body2" fontWeight='bold'>Created: <strong>{new Date(jobDetails.postDate).toLocaleString()}</strong></Typography>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* View Public Job Page */}
      {/* <Button
        fullWidth
        variant="outlined"
        sx={{
          textTransform: "none",
          color: "#f2426d",
          borderColor: "#f2426d",
          borderRadius: "6px",
          mb: 2,
        }}
      >
        View Public Job Page
      </Button> */}

      {/* Social Share */}
      {/* <Typography sx={{ color: '#a1a4b5', }} variant="caption" display="block" gutterBottom>
        Promote this job for more candidates:
      </Typography>
      <Box display="flex" justifyContent="space-around">
        <IconButton sx={{ color: "#4267B2" }}>
          <ShareIcon />
        </IconButton>
        <IconButton sx={{ color: "#1DA1F2" }}>
          <TwitterIcon />
        </IconButton>
        <IconButton sx={{ color: "#0077B5" }}>
          <LinkedInIcon />
        </IconButton>
      </Box> */}
    </Paper>
  );
};

export default JobDetailsCard;
