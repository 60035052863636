import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { getOtherMedia } from "../../api/common/fawApi"; 

const MediaScroll = ({ data }) => {
  const navigate = useNavigate();
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchOtherMedia = async () => {
      try {
        const response = await getOtherMedia(data._id, "User"); 
        setMedia(response);
      } catch (error) {
        console.error("Error fetching media:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOtherMedia();
  }, [data._id]);
  
  const handleCardClick = (mediaId) => {
    navigate(`/media/${mediaId}`);
  };

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      const scrollAmount = clientWidth * 0.7; // Adjust scroll amount
      scrollRef.current.scrollTo({
        left: direction === "left" ? scrollLeft - scrollAmount : scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
      <IconButton onClick={() => scroll("left")} sx={{ zIndex: 1 }}>
        <ArrowBack />
      </IconButton>

      <Box
        ref={scrollRef}
        sx={{
          overflowX: "auto",
          display: "flex",
          gap: 2,
          p: 2,
          whiteSpace: "nowrap",
          scrollBehavior: "smooth",
          flexGrow: 1,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : media.length > 0 ? (
          media.map((item) => (
            <Box
              key={item._id}
              sx={{
                cursor: "pointer",
                minWidth: 120,
                maxWidth: 160,
                textAlign: "center",
              }}
              onClick={() => handleCardClick(item._id)}
            >
              <img
                src={item.media.thumbnail.url}
                alt={item.header}
                style={{ width: "100%", borderRadius: 8 }}
              />
            </Box>
          ))
        ) : (
          <Typography>No media available</Typography>
        )}
      </Box>

      <IconButton onClick={() => scroll("right")} sx={{ zIndex: 1 }}>
        <ArrowForward />
      </IconButton>
    </Box>
  );
};

export default MediaScroll;
