import React, { useState } from "react";
import { useLocation, } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import AdminDrawerList from "../../../../components/Drawer/AdminDrawerList";
import "./EmployerJobDetails.css";
import EmployerJobStats from "./EmployerJobStats/EmployerJobStats";
import CandidateDetails from "./CandidateDetails/CandidateDetails";

const EmployerJobDetails = () => {
  const [activeTab, setActiveTab] = useState("Jobs");
  const location = useLocation();
  const { jobId, organizationId } = location.state || {}; // Access the passed state

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
  };

  
  return (
    <AdminDrawerList>
      <Box sx={{ display: "flex", mt: 2, mb: 5 }}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          textColor="secondary"
          indicatorColor="white"
          sx={{
            "& .MuiTab-root": {
              fontWeight: 600,
              textTransform: "none",
              borderRadius: "8px",
              bgcolor: "#f5f5f5",
              px: 12, // Add padding inside the tabs for better aesthetics
              "&:not(:last-child)": {
                mr: 0, // Remove margin/gap between tabs
              },
              "&.Mui-selected": {
                bgcolor: "green",
                color: "white",
              },
            },
          }}
        >
          <Tab value="Jobs" label="Jobs" />
          <Tab value="Candidates" label="Candidates" />
        </Tabs>
      </Box>

      {/* Dynamic Content */}
      <Box>
        {activeTab === "Jobs" && (
          <EmployerJobStats jobId={jobId} organizationId={organizationId} />
        )}
        {activeTab === "Candidates" && (
          <CandidateDetails jobId={jobId} organizationId={organizationId} />
        )}
      </Box>
    </AdminDrawerList>
  );
};

export default EmployerJobDetails;
