export const departmentOptions = [
  "BFSI, Investments & Trading",
  "Customer Success, Service & Operations",
  "Data Science & Analytics",
  "Engineering - Hardware & Networks",
  "Engineering - Software & QA",
  "Finance & Accounting",
  "Human Resources",
  "IT & Information Security",
  "Marketing & Communication",
  "Product Management",
  "Production, Manufacturing & Engineering",
  "Project & Program Management",
  "Quality Assurance",
  "Sales & Business Development",
  "UX, Design & Architecture",
  "Administration & Facilities",
  "Aviation & Aerospace",
  "Construction & Site Engineering",
  "Consulting",
  "Content, Editorial & Journalism",
  "CSR & Social Service",
  "Energy & Mining",
  "Environment Health & Safety",
  "Food, Beverage & Hospitality",
  "Healthcare & Life Sciences",
  "Legal & Regulatory",
  "Media Production & Entertainment",
  "Merchandising, Retail & eCommerce",
  "Procurement & Supply Chain",
  "Research & Development",
  "Risk Management & Compliance",
  "Security Services",
  "Shipping & Maritime",
  "Sports, Fitness & Personal Care",
  "Strategic & Top Management",
  "Teaching & Training",
  "Other",
];
export const pgEducations = [
  "LLM",
  "M.A",
  "M.Arch",
  "M.Ch",
  "M.Com",
  "M.Des.",
  "M.Ed",
  "M.Pharma",
  "M.Tech",
  "MBA/PGDM",
  "MCA",
  "MCM",
  "MDS",
  "MFA",
  "MS/M.Sc(Science)",
  "MVSC",
  "Medical-MS/MD",
  "PG Diploma",
  "Other Post Graduate",
];

export const ugEducations = [
  "Any Computers(Degree/Diploma)",
  "Any Engineering",
  "Any Hotel-Management",
  "Any Management",
  "Any Medical",
  "B.Tech/B.E (From Top Ranked Colleges)",
  "ITI",
  "Journalism/Mass Communication",
  "UG Popular Course",
  "Vocational-Training",
  "B.A",
  "B.Arch",
  "B.B.A/ B.M.S",
  "B.Com",
  "B.Des.",
  "B.Ed",
  "B.El.Ed",
  "B.P.Ed",
  "B.Pharma",
  "B.Sc",
  "B.Tech/B.E.",
  "B.U.M.S",
  "BAMS",
  "BCA",
  "BDS",
  "BFA",
  "BHM",
  "BHMCT",
  "BHMS",
  "BVSC",
  "Diploma",
  "ITI Certification",
  "LLB",
  "MBBS",
  "Other Graduate",
];

export const jobTypeOptions = [
  { label: "Part Time", value: "partTime" },
  { label: "Full Time", value: "fullTime" },
  { label: "Freelance", value: "freelance" },
  { label: "Contract", value: "contract" },
  { label: "Internship", value: "internShip" },
  { label: "Remote", value: "remote" },
];

export const experienceOptions = [
  "Fresher",
  "0-1 year",
  "1-2 years",
  "2-3 years",
  "3-5 years",
  "5-7 years",
  "7-10 years",
  "10-15 years",
  "15+ years",
];

export const keySkills = [
  "Java",
  "Python",
  "C++",
  "JavaScript",
  "ReactJS",
  "AngularJS",
  "NodeJS",
  "ExpressJS",
  "MongoDB",
  "SQL",
  "NoSQL",
  "HTML",
  "CSS",
  "Bootstrap",
  "Material UI",
  "Redux",
  "TypeScript",
  "Django",
  "Flask",
  "RESTful API",
  "GraphQL",
  "AWS",
  "Azure",
  "GCP",
  "Docker",
  "Kubernetes",
  "Jenkins",
  "Git",
  "JIRA",
  "Confluence",
  "Agile",
  "Scrum",
  "Kanban",
  "TDD",
  "BDD",
  "CI/CD",
  "Microservices",
  "Serverless",
  "Kafka",
  "RabbitMQ",
  "Elasticsearch",
  "Logstash",
  "Kibana",
  "Prometheus",
  "Grafana",
  "Splunk",
  "New Relic",
  "Datadog",
  "Dynatrace",
  "AppDynamics",
  "Nagios",
  "Zabbix",
  "Icinga",
  "Sensu",
  "PagerDuty",
  "VictorOps",
  "OpsGenie",
  "BigPanda",
  "Moogsoft",
  "xMatters",
  "Sumo Logic",
  "Loggly",
  "Logentries",
  "Papertrail",
  "Logz.io",
  "SolarWinds",
  "LogRhythm",
  "Graylog",
  "Splunk",
  "Datadog",
  "Dynatrace",
  "AppDynamics",
];

export const salaryOptions = [
  "0-1 LPA",
  "1-2 LPA",
  "2-3 LPA",
  "3-4 LPA",
  "4-5 LPA",
  "5-6 LPA",
  "6-7 LPA",
  "7-8 LPA",
  "8-9 LPA",
  "9-10 LPA",
  "10-12 LPA",
  "12-14 LPA",
  "14-16 LPA",
  "16-18 LPA",
  "18-20 LPA",
  "20-25 LPA",
  "25-30 LPA",
  "30-35 LPA",
  "35-40 LPA",
  "40-45 LPA",
  "45-50 LPA",
  "50-60 LPA",
  "60-70 LPA",
  "70-80 LPA",
  "80-90 LPA",
  "90-100 LPA",
  "100+ LPA",
];

export const industryOptions = [
  "Analytics / KPO / Research",
  "BPM / BPO",
  "IT Services & Consulting",
  "Electronic Components / Semiconductors",
  "Electronics Manufacturing",
  "Emerging Technologies",
  "Hardware & Networking",
  "Internet",
  "Software Product",
  "Banking",
  "Financial Services",
  "FinTech / Payments",
  "Insurance",
  "Investment Banking / Venture Capital / Private Equity",
  "NBFC",
  "Education / Training",
  "E-Learning / EdTech",
  "Auto Components",
  "Automobile",
  "Building Material",
  "Chemicals",
  "Defence & Aerospace",
  "Electrical Equipment",
  "Fertilizers / Pesticides / Agro chemicals",
  "Industrial Automation",
  "Industrial Equipment / Machinery",
  "Iron & Steel",
  "Metals & Mining",
  "Packaging & Containers",
  "Petrochemical / Plastics / Rubber",
  "Pulp & Paper",
  "Aviation",
  "Courier / Logistics",
  "Engineering & Construction",
  "Oil & Gas",
  "Ports & Shipping",
  "Power",
  "Railways",
  "Real Estate",
  "Urban Transport",
  "Water Treatment / Waste Management",
  "Beauty & Personal Care",
  "Beverage",
  "Consumer Electronics & Appliances",
  "Fitness & Wellness",
  "FMCG",
  "Food Processing",
  "Furniture & Furnishing",
  "Gems & Jewellery",
  "Hotels & Restaurants",
  "Leather",
  "Retail",
  "Textile & Apparel",
  "Travel & Tourism",
  "Biotechnology",
  "Clinical Research / Contract Research",
  "Medical Devices & Equipment",
  "Medical Services / Hospital",
  "Pharmaceutical & Life Sciences",
  "Advertising & Marketing",
  "Animation & VFX",
  "Events / Live Entertainment",
  "Film / Music / Entertainment",
  "Gaming",
  "Printing & Publishing",
  "Sports / Leisure & Recreation",
  "Telecom / ISP",
  "TV / Radio",
  "Accounting / Auditing",
  "Architecture / Interior Design",
  "Content Development / Language",
  "Design",
  "Facility Management Services",
  "Law Enforcement / Security Services",
];
